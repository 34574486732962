@media only screen and (min-width: 1500px) {
    .login-title{
        &.xl{
            font-size: 3.5rem;
            line-height: 1.37;
        }
        &.md{
            font-size: 2.5rem;
            line-height: 1.37;
            margin-bottom: 3rem;
        }
    }
    .login-form{
        max-width: 340px;
        margin-left: auto;
    }
    
    .form input{
        margin-bottom: 25px;  
        font-size: 18px;  
    }
    .abs-img{
        position: absolute;
        bottom: 10%;
        left: 30%;
        transform: scale(1.15);
    }
}
@media only screen and (max-width:1024px) {


.abs-img {
    position: absolute;
    bottom: 10%;
    left: 9vh;
    transform: none;
    width: 40%;
}
}
@media only screen and (max-width: 767px) {
    .login {
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .navbar-brand
    {
        img{
            width: 60%;
        }
    }
    .abs-img {
        order: 2;
        max-width: 100%;
        left: 50%;
        position: relative;
        transform: translate(-50%);
        right: unset;
        margin: 5vh 0 0;
    }
    .navbar{
        margin-bottom: 20px;
        padding: 20px;
        position: fixed;
        background: white;
        left: 0;
        width: 100%;
        order: 1;
        top: 0;
        position: sticky;
    }
    .login-form
    {
        margin: auto;
    }
    .login-title.xl{
        margin: 20px 0 0;
        font-size: 1.6rem;
        line-height: normal;
        text-align:center;
    }
    .p-16vh {
        height: auto;
        display: flex;
        align-items: self-end;
        margin-top: 40px;
        order: 3;

    }
}




@media only screen and (max-width: 1200px) {
    .flex-item{
    flex-wrap: wrap;
    // flex-wrap: wrap;
        &.no-wrap
        {
            flex-wrap: nowrap;
            input{
            .btn-theme {

            }
            }
        }
        &.inputs{
            flex-wrap: nowrap;
            input{
                margin-right: 2vw;
            }

        }
    }

}
@media only screen and (max-width: 768px) {
    .flex-item{
        justify-content: center;
            &.inputs{
                flex-wrap: wrap;
                justify-content: flex-end;
                input{
                    margin-bottom: 10px;
                    margin-right: 0;
                }
    
            }
        }
}
