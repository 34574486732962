.sidenav {
    height: 100%;
    width: 70px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $theme;
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;

    .sidenav-items {
        a {
            padding: 8px 0px 8px 0px;
            text-decoration: none;
            font-size: 25px;
            color: #fff;
            display: block;
            transition: 0.3s;
            margin: 15px 0;
            display: flex;
            align-items: center;

            &:hover {
                color: #f1f1f1;
            }

            img {
                filter: brightness(0) invert(1);
                border-radius: 50px;
                padding: 10px;
            }
            .icon i {
                padding: 10px;

            }
            &.active,&:hover {
                
                .icon i {
                    color: $theme;
                    background-color: white;
                    border-radius: 50px;
                    padding: 10px;
                    line-height: 0;
                    display: block;
                    height: 45px;
                    transition: 0.3s;

                    
                }
                img {

                    filter: none;
                    background-color: white;
                    transition: 0.3s;
                }
            }
        }

        .name {
            display: none;
        }
    }

    &.expand {
        width: 250px;

        .sidenav-items {

            .name {
                display: block;
                font-size: 20px;
                line-height: 25px;
            }

            a {

                img,i {
                    margin-right: 10px;
                }

                &.active img {
                    margin-right: 15px;

                }

            }
        }
    }
}

.main {
    transition: margin-left .5s;
    // padding: 30px 40px;
    margin-left: 70px;

    &.ml-70 {
        margin-left: 250px;
    }

    .logo img {
        width: 170px;

    }
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.arrow-sidebar {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 45px;
    top: 80px;
    cursor: pointer;
    // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-color: white;
    border-radius: 50px;
    @include flex-align-center;
    justify-content: center;
    transition: 0.5s;
    z-index: 10;

    &.active {
        transform: none;
        left: 220px;
    }
}

.header {
    @include flex-align-center;
    padding: 30px 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);

    .user-detail {
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;

        img {
            max-width: 50px;
            margin-right: 10px;
        }

    }
}

.main-content {
    padding: 15px 40px;
}

.inputs {
    margin: 30px 0;

    input {
        margin-right: 30px;
        margin-bottom: 0;
    }
}



#modaladd {
    .modal-content {
        padding: 15px;
        border-radius: 22px;
    }
}

@media only screen and (max-width: 1200px) {
    .sidenav .sidenav-items a {

        margin: 10px 0;

    }

    .sidenav {
        width: 50px;

        &.expand {
            width: 200px;
        }

        .sidenav-items a img {
            padding: 6px;
        }
    }

    .arrow-sidebar {
        left: 25px;

        &.active {
            left: 175px;
        }
    }

    .main-content {
        padding: 15px 20px 15px 30px;

    }

    .header {
        padding: 20px 15px;

        .user-detail img {
            max-width: 35px;
            margin-right: 10px;
        }
    }

    .main {
        margin-left: 50px;

        .logo img {
            width: 140px;
        }

        &.ml-70 {
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .sidenav .sidenav-items a {

        margin: 10px 0;

    }

    .arrow-sidebar {
        left: 25px;
        top: unset;
        bottom: 30px;
        position: fixed;
    }

    .main-content {
        padding: 15px;
    }

    .flex-item.inputs {
        visibility: collapse;
        height: 0;
        margin: 20px 0;
    }

    .filter-block {
        &.expand {
            .inputs {
                visibility: visible;
                transition: 1s;
                height: auto;
            }
        }
    }
}