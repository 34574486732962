:root,
html,body{
	font-family: $font-primary;
	-webkit-font-smoothing: antialiased !important;
}
html
{
	position: relative;
	font-family: $font-primary;
	-webkit-font-smoothing: antialiased;
}
body{
    font-family: $font-primary;
	font-size: 16px;
	line-height: 1.1;
	font-weight: normal;
	position: relative;
	padding: 0 !important;
	-webkit-font-smoothing: antialiased !important;
}
a {
	transition: .3s all ease;
	color: $theme ;
	&:hover, &:focus {
		text-decoration: none !important;
		outline: none !important;
		box-shadow: none;
		color: $link-color ;
	}
}
button {
	transition: .3s all ease;
	&:hover, &:focus {
		text-decoration: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
}
.small, small {
    font-size: 80%;
    font-weight: 700;
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	line-height: 1.5;
	font-weight: 400;
	font-family: $font-primary;
	color: $theme-dark;
}

ul{
	padding-inline-start: 20px;
	li{
		list-style-type: none;

	}
}
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: rgba(0,0,0,0.1); /* or add it to the track */
	border-radius: 5px;
  }
::-webkit-scrollbar-thumb {
	background: $theme;
	border-radius: 5px;

  }
.text-sm {
    font-size: 0.875rem !important;
}
.text-xs {
    font-size: 0.75rem !important;
}
.text-xxs {
    font-size: 0.65rem !important;
}

.mr-30{
	margin-right: 30px;
}
