.login-title{
    font-weight: 700;
    &.xl{
        font-size: 2.75rem;
        line-height: 1.37;
    }
    &.md{
        font-size: 1.875rem;
        line-height: 1.37;
        margin-bottom: 3rem;
    }
}
.sub-heading{
    opacity: 0.5;
    margin-top: 5px;
}
.heading {
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    &.theme
    {
        color: $theme;
    }
}
@media only screen and (max-width: 768px) {
    .heading {
        font-size: 18px;
        margin-bottom: 10px;
    }
}