// colors
$theme: #217C78;
$link-color: #217C78;
$theme-dark: #111111;
$theme-dmedium: #252525;
$theme-light: #ECECEC;
$input-bg:#F3F4F4;
$trans-bg: rgba(46, 200, 193, 0.1);

// fonts
$font-primary : 'Nunito', sans-serif;


// border
$border-light: 1px solid $theme-light;



@mixin flex-align-center
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}