@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900&display=swap");
:root,
html, body {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

html {
  position: relative;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 1.1;
  font-weight: normal;
  position: relative;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased !important;
}

a {
  transition: 0.3s all ease;
  color: #217C78;
}
a:hover, a:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none;
  color: #217C78;
}

button {
  transition: 0.3s all ease;
}
button:hover, button:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.small, small {
  font-size: 80%;
  font-weight: 700;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  color: #111111;
}

ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
ul li {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1); /* or add it to the track */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #217C78;
  border-radius: 5px;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-xxs {
  font-size: 0.65rem !important;
}

.btn-theme {
  margin: 0;
  padding: 8px 25px;
  height: unset;
  margin-left: 10px;
  font-weight: 700;
  background-color: #217C78;
  color: white;
  box-shadow: none;
  border-radius: 8px;
  font-size: 18px;
}
.btn-theme.outline {
  border: 1px solid #217C78;
  background-color: white;
  color: #217C78;
}
.btn-theme:hover {
  background-color: #111111;
  color: white;
  transition: 0.5s;
}
.btn-theme.login-btn {
  box-shadow: 0px 5px 14px 6px rgba(33, 124, 120, 0.25);
  height: 56px;
  margin: 50px 0;
  width: 100%;
  color: white;
  padding: 0;
}
.btn-theme.dark {
  background-color: #111111;
}

.main-content .search-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  max-width: 50px;
  margin-left: 0 !important;
  height: 46px;
}
.main-content .filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  max-width: 50px;
  height: 36px;
  display: none;
  padding: 6px 10px;
}
.main-content .filter:hover img {
  filter: brightness(0) invert(1);
}

.modal form {
  margin-top: 20px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  border: none;
}
.modal-footer .btn {
  margin: 0;
  width: 49%;
}

@media only screen and (max-width: 1200px) {
  .btn-theme {
    padding: 5px 15px;
    font-size: 16px;
  }
  .main-content .search-btn {
    max-width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .btn-theme {
    padding: 5px 15px;
    font-size: 14px;
  }
  .main-content .filter {
    display: block;
  }
}
.inpt {
  margin-bottom: 20px;
  background: rgba(46, 200, 193, 0.1);
  border-radius: 8px;
  border-color: rgba(46, 200, 193, 0.1);
  font-size: 14px;
  height: 46px;
}
.inpt[type=file] {
  padding: 12px;
  margin-bottom: 0;
}
.inpt::-webkit-file-upload-button {
  background-color: white;
  margin-left: 2px;
  border-radius: 12px;
}
.inpt:focus {
  background-color: white;
  box-shadow: none;
  border-color: #217C78;
}
.inpt::-moz-placeholder {
  text-transform: capitalize;
}
.inpt::placeholder {
  text-transform: capitalize;
}
.inpt.search {
  margin-bottom: 0;
}

@media only screen and (max-width: 1200px) {
  .inpt {
    height: 40px;
  }
  .inpt.search {
    margin-bottom: 0;
    height: -webkit-fill-available;
  }
}
.login-title {
  font-weight: 700;
}
.login-title.xl {
  font-size: 2.75rem;
  line-height: 1.37;
}
.login-title.md {
  font-size: 1.875rem;
  line-height: 1.37;
  margin-bottom: 3rem;
}

.sub-heading {
  opacity: 0.5;
  margin-top: 5px;
}

.heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
}
.heading.theme {
  color: #217C78;
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.sidenav {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #217C78;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.sidenav .sidenav-items a {
  padding: 8px 0px 8px 0px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.sidenav .sidenav-items a:hover {
  color: #f1f1f1;
}
.sidenav .sidenav-items a img {
  filter: brightness(0) invert(1);
  border-radius: 50px;
  padding: 10px;
}
.sidenav .sidenav-items a.active img {
  filter: none;
  background-color: white;
}
.sidenav .sidenav-items .name {
  display: none;
}
.sidenav.expand {
  width: 250px;
}
.sidenav.expand .sidenav-items .name {
  display: block;
  font-size: 20px;
  line-height: 25px;
}
.sidenav.expand .sidenav-items a img {
  margin-right: 10px;
}
.sidenav.expand .sidenav-items a.active img {
  margin-right: 15px;
}

.main {
  transition: margin-left 0.5s;
  margin-left: 70px;
}
.main.ml-70 {
  margin-left: 250px;
}
.main .logo img {
  width: 170px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.arrow-sidebar {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 45px;
  top: 80px;
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  z-index: 10;
}
.arrow-sidebar.active {
  transform: none;
  left: 220px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.header .user-detail {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}
.header .user-detail img {
  max-width: 50px;
  margin-right: 10px;
}

.main-content {
  padding: 15px 40px;
}

.inputs {
  margin: 30px 0;
}
.inputs input {
  margin-right: 30px;
  margin-bottom: 0;
}

#modaladd .modal-content {
  padding: 15px;
  border-radius: 22px;
}

@media only screen and (max-width: 1200px) {
  .sidenav .sidenav-items a {
    margin: 10px 0;
  }
  .sidenav {
    width: 50px;
  }
  .sidenav.expand {
    width: 200px;
  }
  .sidenav .sidenav-items a img {
    padding: 6px;
  }
  .arrow-sidebar {
    left: 25px;
  }
  .arrow-sidebar.active {
    left: 175px;
  }
  .main-content {
    padding: 15px 20px 15px 30px;
  }
  .header {
    padding: 20px 15px;
  }
  .header .user-detail img {
    max-width: 35px;
    margin-right: 10px;
  }
  .main {
    margin-left: 50px;
  }
  .main .logo img {
    width: 140px;
  }
  .main.ml-70 {
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .sidenav .sidenav-items a {
    margin: 10px 0;
  }
  .arrow-sidebar {
    left: 25px;
    top: unset;
    bottom: 30px;
    position: fixed;
  }
  .main-content {
    padding: 15px;
  }
  .flex-item.inputs {
    visibility: collapse;
    height: 0;
    margin: 20px 0;
  }
  .filter-block.expand .inputs {
    visibility: visible;
    transition: 1s;
    height: auto;
  }
}
.pagination {
  margin: 15px 0 0;
  justify-content: flex-end;
}
.pagination a {
  font-weight: bold;
  font-size: 16px;
  color: rgba(120, 124, 140, 0.7);
  float: left;
  padding: 8px 8px;
  text-decoration: none;
}
.pagination a.active {
  color: #217C78;
}
.pagination a:hover:not(.active) {
  background-color: rgba(33, 124, 120, 0.3);
  color: #217C78;
  border-radius: 8px;
}

.no-wrap {
  white-space: nowrap;
}

.links {
  margin: 30px 0 10px;
  align-items: stretch;
}
.links a.dark {
  margin-left: 0;
  margin-right: 10px;
}
.links .flex-item {
  flex-wrap: nowrap;
  align-items: stretch;
  margin-top: 15px;
}
.links .link-box {
  background-color: #F3F4F4;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  padding: 0.375rem 0.75rem;
  align-items: center;
  display: inline-flex;
  max-width: calc(100% - 135px);
  width: 100%;
}
.links .link-box a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
}

.redirect-sec .btn-theme {
  width: 50%;
  margin: 2rem auto 1rem;
}

@media only screen and (max-width: 992px) {
  .redirect-img {
    max-width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .links span {
    display: none;
  }
  .links .link-box {
    max-width: calc(100% - 70px);
  }
  .redirect-img {
    margin: auto;
    display: block;
  }
}
.table-responsive {
  max-height: calc(100vh - 330px);
  overflow: auto;
}

.table-home {
  margin: 0;
  border-collapse: separate;
  border-spacing: 0 15px;
  --bs-table-striped-bg: #F4FDFD;
  padding-right: 10px;
}
.table-home thead {
  position: sticky;
  top: 0;
}
.table-home thead tr {
  background: white;
  border-radius: 0;
}
.table-home tr {
  border: 1px solid rgba(190, 190, 190, 0.5);
  border-radius: 8px;
}
.table-home tr td,
.table-home tr th {
  padding: 15px;
  text-align: center;
  border-width: 1px 0px 1px 0px;
  white-space: nowrap;
}
.table-home tr td:first-child,
.table-home tr th:first-child {
  text-align: left;
  color: #04103B;
  border-left: 1px solid rgba(190, 190, 190, 0.5);
  border-radius: 10px 0px 0px 10px;
}
.table-home tr th {
  border: none;
  padding: 15px;
}
.table-home tr th:first-child {
  border: none;
}
.table-home tr td:last-child {
  border-right: 1px solid rgba(190, 190, 190, 0.5);
  border-radius: 0px 10px 10px 0px;
  width: 150px;
}
.table-home tr td:last-child span {
  padding: 5px;
}
.table-home tr td:last-child span:hover {
  background-color: #217C78;
  border-radius: 5px;
}
.table-home tr td:last-child span:hover img {
  filter: brightness(0) invert(1);
}
.table-home tr td:last-child span:last-child {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}
.table-home tr td:last-child span:last-child img {
  filter: none;
}
.table-home tr td:last-child img {
  margin-left: 10px;
  width: 24px;
  height: 24px;
}
.table-home tr td:last-child img:last-child {
  height: 20px;
  margin: 0;
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  /* background: rgba(0, 0, 0, 0.2); */
}
.loader img {
  height: 490px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.toast {
  --bs-toast-bg:#F4FDFD !important;
}

@media only screen and (max-width: 768px) {
  .table-responsive {
    overflow: auto;
    max-height: 68vh;
  }
  .table-responsive.expand {
    max-height: 47vh;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login {
  padding: 9vh 11vh 0;
  overflow: hidden;
  position: relative;
}
.login .navbar-collapse {
  justify-content: end;
}
.login .navbar-collapse .nav-item {
  text-transform: capitalize;
  font-weight: 700;
}
.login .navbar-collapse .nav-item .nav-link {
  margin: 0.5rem 0rem 0.5rem 1.25rem;
}
.login .navbar-collapse .nav-item .nav-link.active {
  color: #217C78;
  position: relative;
}
.login .navbar-collapse .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 30%;
  border: 2px solid #217C78;
  left: 50%;
  transform: translate(-50%);
  border-radius: 5px;
}

.p-16vh {
  height: calc(91vh - 70px);
  display: flex;
  align-items: center;
}
.p-16vh .row {
  width: calc(100% + 1rem);
}

.flex-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-form {
  text-align: center;
}

.absolute-item {
  position: absolute;
}
.absolute-item .circle {
  position: absolute;
  width: 120px;
  height: 170px;
  left: 50%;
  top: 10px;
  background: #D5FFFA;
  filter: blur(50px);
  transform: rotate(25.62deg);
}
.absolute-item .circle.two {
  position: absolute;
  width: 164px;
  height: 315px;
  left: 50%;
  top: -119px;
  background: #EBF0FE;
  filter: blur(87px);
  transform: rotate(25.62deg);
}
.absolute-item.one {
  left: 50%;
  top: -13%;
  z-index: -1;
}
.absolute-item.one .circle {
  left: 50%;
  top: -119px;
}
.absolute-item.one .circle.two {
  left: 50%;
  top: -119px;
}
.absolute-item.two {
  right: 10%;
  bottom: 15%;
  transform: scale(1.1);
  z-index: -1;
}
.absolute-item.two .circle {
  right: 10%;
  bottom: 0;
}
.absolute-item.two .circle.two {
  right: 50%;
  bottom: 0;
}
.absolute-item.three {
  left: 10%;
  top: 45%;
  transform: scale(2) translate(-50%);
  z-index: -1;
}
.absolute-item.three .circle {
  left: 10%;
  bottom: 0;
}
.absolute-item.three .circle.two {
  left: 50%;
  bottom: 0;
}
.absolute-item.four {
  right: 30%;
  top: 45%;
  transform: scale(2) translate(-50%);
  z-index: -1;
}
.absolute-item.four .circle {
  left: 10%;
  bottom: 0;
}
.absolute-item.four .circle.two {
  left: 50%;
  bottom: 0;
}

.abs-img {
  position: absolute;
  bottom: 10%;
  left: 20%;
  transform: scale(0.9);
}

@media only screen and (max-width: 768px) {
  .absolute-item {
    position: absolute;
  }
  .absolute-item.three, .absolute-item.two, .absolute-item.one {
    top: 0;
    left: 0;
    bottom: unset;
    right: unset;
  }
  .navbar {
    order: 1;
    top: 0;
    position: sticky;
  }
  .abs-img {
    order: 2;
    max-width: 100%;
    left: 50%;
    position: relative;
    transform: translate(-50%);
    right: unset;
    margin: 5vh 0 0;
  }
  .absolute-item.four {
    display: none;
  }
}
.form .frgt-link {
  text-align: right;
}
.form .frgt-link a {
  text-decoration: none;
  color: #252525;
  opacity: 0.5;
}
.form input {
  margin-bottom: 20px;
  background: rgba(46, 200, 193, 0.1);
  border-radius: 8px;
  border-color: rgba(46, 200, 193, 0.1);
  font-size: 14px;
  height: 46px;
}
.form input:focus {
  background-color: white;
  box-shadow: none;
  border-color: #217C78;
}
.form input::-moz-placeholder {
  text-transform: capitalize;
}
.form input::placeholder {
  text-transform: capitalize;
}

.login-form {
  max-width: 300px;
  margin-left: auto;
}

@media only screen and (min-width: 1500px) {
  .login-title.xl {
    font-size: 3.5rem;
    line-height: 1.37;
  }
  .login-title.md {
    font-size: 2.5rem;
    line-height: 1.37;
    margin-bottom: 3rem;
  }
  .login-form {
    max-width: 340px;
    margin-left: auto;
  }
  .form input {
    margin-bottom: 25px;
    font-size: 18px;
  }
  .abs-img {
    position: absolute;
    bottom: 10%;
    left: 30%;
    transform: scale(1.15);
  }
}
@media only screen and (max-width: 1024px) {
  .abs-img {
    position: absolute;
    bottom: 10%;
    left: 9vh;
    transform: none;
    width: 40%;
  }
}
@media only screen and (max-width: 767px) {
  .login {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .navbar-brand img {
    width: 60%;
  }
  .abs-img {
    order: 2;
    max-width: 100%;
    left: 50%;
    position: relative;
    transform: translate(-50%);
    right: unset;
    margin: 5vh 0 0;
  }
  .navbar {
    margin-bottom: 20px;
    padding: 20px;
    position: fixed;
    background: white;
    left: 0;
    width: 100%;
    order: 1;
    top: 0;
    position: sticky;
  }
  .login-form {
    margin: auto;
  }
  .login-title.xl {
    margin: 20px 0 0;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
  }
  .p-16vh {
    height: auto;
    display: flex;
    align-items: self-end;
    margin-top: 40px;
    order: 3;
  }
}
@media only screen and (max-width: 1200px) {
  .flex-item {
    flex-wrap: wrap;
  }
  .flex-item.no-wrap {
    flex-wrap: nowrap;
  }
  .flex-item.inputs {
    flex-wrap: nowrap;
  }
  .flex-item.inputs input {
    margin-right: 2vw;
  }
}
@media only screen and (max-width: 768px) {
  .flex-item {
    justify-content: center;
  }
  .flex-item.inputs {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .flex-item.inputs input {
    margin-bottom: 10px;
    margin-right: 0;
  }
}/*# sourceMappingURL=style.css.map */