.form{
    .frgt-link{
       text-align: right;
       a{
        text-decoration: none;
        color: $theme-dmedium;
        opacity: 0.5;
       }
    }
    input{
        margin-bottom: 20px;
        background: $trans-bg;
        border-radius: 8px;
        border-color: $trans-bg;
        font-size: 14px;
        height: 46px;
        &:focus
        {
            background-color: white;
            box-shadow: none;
        border-color: $theme;

        }
        &::placeholder
        {
            text-transform: capitalize;
        }
    }
}
.login-form{
    max-width: 300px;
    margin-left: auto;
}
