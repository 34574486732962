.no-wrap {
    white-space: nowrap;
}

.links {
    margin: 30px 0 10px;
    align-items: stretch;

    a.dark {
        margin-left: 0;
        margin-right: 10px;
    }

    .flex-item {
        flex-wrap: nowrap;
        align-items: stretch;
        margin-top: 15px;
    }

    .link-box {
        background-color: $input-bg;
        border: $border-light;
        border-radius: 8px;
        padding: 0.375rem 0.75rem;
        align-items: center;
        display: inline-flex;
        max-width: calc(100% - 135px);
        width: 100%;

        a {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-decoration: none;
        }
    }
}

.redirect-sec {
    .btn-theme{
        width: 50%;
        margin: 2rem auto 1rem;
    }
}

@media only screen and (max-width: 992px) {
    .redirect-img {
        max-width: 40%;

    }
}

@media only screen and (max-width: 768px) {

    .links {
        span {
            display: none
        }

        .link-box {
            max-width: calc(100% - 70px);
        }
    
    }

    .redirect-img {
        margin: auto;
        display: block;
    }
}