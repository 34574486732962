.pagination {
    margin: 15px 0 0;
    justify-content: flex-end;
    a {
        font-weight: bold;
        font-size: 16px;
        color: rgba(120, 124, 140, 0.7);
        float: left;
        padding: 8px 8px;
        text-decoration: none;
        &.active {
            // background-color: $theme;
            color: $theme;
        }
        &:hover {
            &:not(.active) {
                background-color: rgba(33, 124, 120, 0.3);
                color: $theme;
                border-radius: 8px;
            }
        }
    }
}