.btn-theme {
    margin: 0;
    padding: 8px 25px;
    height: unset;
    margin-left: 10px;
    font-weight: 700;
    background-color: $theme;
    color: white;
    box-shadow: none;
    border-radius: 8px;
    font-size: 18px;

    &.outline {
        border: 1px solid $theme;
        background-color: white;
        color: $theme;
    }

    &:hover {
        background-color: $theme-dark;
        color: white;
        transition: 0.5s;

    }

    &.login-btn {
        box-shadow: 0px 5px 14px 6px rgba(33, 124, 120, 0.25);
        height: 56px;
        margin: 50px 0;
        width: 100%;
        color: white;
        padding: 0

    }
    &.dark{
        background-color: $theme-dark;
    }
}

.main-content {
        .search-btn {
            @include flex-align-center;
            justify-content: center;
            max-width: 50px;
            margin-left: 0 !important;
            height: 46px;

        }
        .filter{
            @include flex-align-center;
            justify-content: center;
            max-width: 50px;
            height:36px;
            display: none;
            padding: 6px 10px;
            &:hover{
                img{
                    filter: brightness(0) invert(1);
                }
            }
        }
}
.modal{
    form{
        margin-top: 20px;
    }
}
.modal-footer {
    display: flex;
    justify-content: space-between;
border: none;
    .btn {
        margin: 0;
        width: 49%;
    }
}

// responsive
@media only screen and (max-width: 1200px) {
.btn-theme
{
    padding: 5px 15px;
    font-size: 16px;
}
.main-content
{
    .search-btn
    {
        max-width: 40px;
    height: 40px;
    }
}
}
@media only screen and (max-width: 768px) {
    .btn-theme
{
    padding: 5px 15px;
    font-size: 14px;
}
.main-content .filter{
display: block;    
}
}
