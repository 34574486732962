.table-responsive {
    max-height: calc(100vh - 330px);
    overflow: auto;
    &.seller{
    max-height: calc(100vh - 257px);

    }
}

.table-home {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0 15px;
    --bs-table-striped-bg: #F4FDFD;
    padding-right: 10px;

    thead {
        position: sticky;
        top: 0;

        tr {
            background: white;
            border-radius: 0;
        }
    }

    tr {
        border: 1px solid rgba(190, 190, 190, 0.5);
        border-radius: 8px;


        td,
        th {
            padding: 15px;
            // border: none;
            text-align: center;
            border-width: 1px 0px 1px 0px;
            white-space: nowrap;

            &:first-child {
                text-align: left;
                color: #04103B;
                border-left: 1px solid rgba(190, 190, 190, 0.5);
                border-radius: 10px 0px 0px 10px;

            }
        }

        th {
            border: none;
            padding: 15px;
        }

        th:first-child {
            border: none
        }

        td:last-child {
            border-right: 1px solid rgba(190, 190, 190, 0.5);
            border-radius: 0px 10px 10px 0px;
            width: 150px;

            span {
                padding: 5px;
                font-size: 20px;
                cursor: pointer;
                
                &:hover {
                    // animation: icon-animate 1s linear 0s infinite alternate ;
                    transition: 0.3s;
                    background-color: $theme;
                    border-radius: 5px;
                    color: white;
                    img {
                        filter: brightness(0) invert(1);
                    }

                }

                // &:last-child {
                //     background-color: transparent;
                //     cursor: pointer;
                //     padding: 0;
                //     img {
                //         filter: none;
                //     }
                //     &.last{
                        
                //     }
                // }
            }

            img {
                margin-left: 10px;
                width: 24px;
                height: 24px;

                &:last-child {
                    height: 20px;
                    margin: 0;

                }

            }
        }
    }
}

.loader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    // background: rgba(0, 0, 0, 0.7);

    img {
        height: 490px;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
}
.toast{
    --bs-toast-bg:#F4FDFD  !important;
}
@media only screen and (max-width: 768px) {
    .table-responsive {
        overflow: auto;
        max-height: 68vh;
        &.expand{
            max-height: 47vh;
        }
    }
}

// @keyframes icon-animate {
//     0%   {transform:rotate(-5deg) ;}
//     50%   {transform:rotate(0deg) ;}
//     100%   {transform:rotate(5deg) ;}
//   }