.header{
    @include flex-align-center;

}
.login{
    padding: 9vh 11vh 0;
    overflow: hidden;
    position: relative;
.navbar-collapse{
    justify-content: end;
    .nav-item{
        text-transform: capitalize;
        font-weight: 700;
        .nav-link{
            margin: 0.5rem 0rem 0.5rem 1.25rem ;
            &.active{
                color: $theme;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    width: 30%;
                    border: 2px solid $theme;
                    left: 50%;
                    transform: translate(-50%);
                    border-radius: 5px;
                }
            }
        }
    }
}
}

.p-16vh{
    height: calc(100vh - 9vh - 70px);
    display: flex;
    align-items: center;
    .row{
        width: calc(100% + 1rem);
    }
}
.flex-item{
    @include flex-align-center;
}

.login-form
{
    text-align: center;
}
.absolute-item{
        position: absolute;
       
        .circle{
            position: absolute;
            width: 120px;
            height: 170px;
            left: 50%;
            top: 10px;
            background: #D5FFFA;
            filter: blur(50px);
            transform: rotate(25.62deg);
            &.two{
                position: absolute;
                width: 164px;
                height: 315px;
                left: 50%;
                top: -119px;
                background: #EBF0FE;
                filter: blur(87px);
                transform: rotate(25.62deg);
            }
    }

    &.one{
        left: 50%;
        top: -13%;
        z-index: -1;
        .circle
        {
            left: 50%;
            top: -119px;
            &.two
            {
                left: 50%;
                top: -119px;
            }
        }
    }
    &.two{
        right: 10%;
        bottom: 15%;
        transform: scale(1.1);
        z-index: -1;
        .circle
        {
            right: 10%;
            bottom: 0;
            &.two
            {
                right: 50%;
                bottom: 0;
            }
        }
    }
    &.three{
        left: 10%;
        top: 45%;
        transform: scale(2) translate(-50%);
        z-index: -1;
        .circle
        {
            left: 10%;
            bottom: 0;
            &.two
            {
                left: 50%;
                bottom: 0;
            }
        }
    }
    &.four{
        right: 30%;
        top: 45%;
        transform: scale(2) translate(-50%);
        z-index: -1;
        .circle
        {
            left: 10%;
            bottom: 0;
            &.two
            {
                left: 50%;
                bottom: 0;
            }
        }
    }
  
}
.abs-img{
    position: absolute;
    bottom: 10%;
    left: 20%;
    transform: scale(0.9);
}

@media only screen and (max-width: 768px) {
   .absolute-item{
        position: absolute;
        &.three,
        &.two,
        &.one{
            top: 0;
            left: 0;
            bottom: unset;
            right: unset;
        }   
    }
    .navbar{
        order: 1;
        top: 0;
        position: sticky;
    }
    .abs-img{
        order: 2;
        max-width: 100%;
        left: 50%;
        position: relative;
        transform: translate(-50%);
        right: unset;
        margin: 5vh 0 0;
    }
.absolute-item.four{
    display: none;
}
}

