.inpt{
    margin-bottom: 20px;
    background: $trans-bg;
    border-radius: 8px;
    border-color: $trans-bg;
    font-size: 14px;
    height: 46px;
    &[type="file"]{
        padding: 12px;
        margin-bottom: 0;

    }
    &::-webkit-file-upload-button {
      background-color: white;
      margin-left: 2px;
    border-radius: 12px;
      }
    &:focus
    {
        background-color: white;
        box-shadow: none;
    border-color: $theme;

    }
    &::placeholder
    {
        text-transform: capitalize;
    }
    &.search{
        margin-bottom: 0;
    }
}
.form-select{
	background-color: $trans-bg;
    height: 46px;
    border-color: $trans-bg;
    &:focus
    {
        box-shadow: none;
        border-color: $theme;
        
    }
    option:hover{
        background: #1b1a1a !important;
    }
}
@media only screen and (max-width: 1200px) {

.inpt{
    height: 40px;
    &.search{
        margin-bottom: 0;
        height: -webkit-fill-available;
    }
}
.form-select{
    height: 40px;
}
}
